import React, { useState } from "react";
import "./App.less";
import { Layout, Menu, Modal, Drawer, Button, Popover } from "antd";
import { Route, Link, Routes, Navigate } from "react-router-dom";
import {
  ExclamationCircleOutlined,
  MenuOutlined,
  HolderOutlined,
  GlobalOutlined,
} from "@ant-design/icons";

import NotFound from "./pages/NotFound/Index";

import Logout from "./components/Logout";
import Bookings from "./pages/Booking/Bookings";

import Setting from "./pages/Content/Setting";
import ContactLead from "./pages/ContactLead/ContactLead";
import LanguageSelector from "./components/language-selector";
import { useTranslation } from "react-i18next";
//new code

function MainLayout(props) {
  const { Header, Content, Footer, Sider } = Layout;
  const { SubMenu } = Menu;
  const { confirm } = Modal;
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedKeys, setselectedKeys] = useState(0);

  const { t } = useTranslation();

  const onClose = () => {
    setOpen(false);
  };
  const onCollapse = (collapsed) => {
    setCollapsed(!collapsed);
  };

  const showLogoutConfirm = (id) => {
    confirm({
      title: t("logout.message"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("logout.yes"),
      okType: "danger",
      cancelText: t("logout.no"),

      onOk() {
        // return handleDelete(id);
        // props.history.push("/logout");
        window.location = "/logout";
      },
      onCancel() {},
    });
  };
  const menuitems = [
    {
      icon: <HolderOutlined />,
      label: <Link to="/bookings">{t("bookings.moduleNamePural")}</Link>,
      key: "bookings",
    },
    {
      icon: <HolderOutlined />,
      label: <Link to="/settings">{t("settings.moduleNamePural")}</Link>,
      key: "settings",
    },
    {
      icon: <HolderOutlined />,
      label: <Link to="/contact">{t("contact.moduleNamePural")}</Link>,
      key: "contact",
    },
  ];
  const languages = [
    {
      key: "en",
      label: "English",
    },
    {
      key: "fr",
      label: "French",
    },
  ];

  const content = <Menu theme="light" mode="inline" items={languages} defaultSelectedKeys={["fr"]}></Menu>;
  const topMenuItems = [
    {
      label: (
         <LanguageSelector/>
        // <Popover content={content}>
        
        //   <GlobalOutlined />
        // </Popover>
      ),
      key: "languageSelector",
    },
    {
      label: (
        <Link
          to=""
          onClick={() => {
            showLogoutConfirm();
          }}
        >
          {t("logout.logout")}
        </Link>
      ),
      key: "Logout",
    }, // remember to pass the key prop
    // { label: "item 2", key: "item-2" }, // which is required
  ];
  const MenuComponent = (
    <Menu theme="light" mode="inline" items={menuitems}></Menu>
  );
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        // theme="dark"
        // collapsible
        // collapsed={collapsed}
        // onCollapse={() => {
        //   onCollapse(collapsed);
        // }}
        theme="light"
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        trigger={null}
      >
        {/* <div className="logo">
            <span>Raymond</span>
            <img src={Logo} className="img-fluid" />
          </div> */}
        {MenuComponent}
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Button
            className="menu"
            type="primary"
            icon={<MenuOutlined />}
            onClick={() => setOpen(true)}
          />
          <Menu theme="dark" mode="horizontal" items={topMenuItems} />
          {/* <Menu.Item key="1" >
                <Link
                to=""
                  onClick={() => {
                    showLogoutConfirm();
                  }}
                >
                  Logout
                </Link>
              </Menu.Item> */}
        </Header>
        <Content style={{ margin: "0 16px" }}>
          <Routes>
            <Route exact path="/" element={<Navigate to="/bookings" />} />
            <Route exact path="/bookings" element={<Bookings />} />
            <Route exact path="/settings" element={<Setting />} />
            <Route exact path="/contact" element={<ContactLead />} />

           

            <Route exact path="/logout" element={<Logout />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          {/* Design & Developed by Togglehead */}
        </Footer>
      </Layout>

      <Drawer
        title={t("common.menu")}
        placement="left"
        onClose={onClose}
        open={open}
      >
        {MenuComponent}
      </Drawer>
    </Layout>
  );
}

export default MainLayout;
