
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
const Index = () => {
  const {t} = useTranslation();

  return (
    <Result
    status="404"
    title="404"
    subTitle={t("notFound.message")}
    // extra={<Button type="primary">Back Home</Button>}
  />
  )
}

export default Index