import React from "react";
import "./App.less";

import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";

import Login from "./pages/Auth/Login";
import MainLayout from "./MainLayout";
import config from "./config/config";
import { jwtDecode } from "jwt-decode";
import Logout from "./components/Logout";
import fr_FR from "antd/locale/fr_FR";
import { ConfigProvider } from "antd";
function App() {
  const PrivateRoute = ({ children, guard = null }) => {
    let user;
    try {
      const token = localStorage.getItem(config.jwt_store_key);
      user = jwtDecode(token);
    } catch (error) {
      console.log({ error });
    }

    if (!user) {
      // user is not authenticated
      return <Navigate to="/login" />;
    }
    return children;
  };


  

  return (
    <ConfigProvider locale={fr_FR}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />

          <Route
            path="/*"
            element={
              <PrivateRoute>
                <MainLayout  />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
