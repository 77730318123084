import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Tooltip,
  Descriptions,
  Drawer,
  Button,
  Space,
  Modal,
  List,
  Tag,
} from "antd";
import { Table } from "ant-table-extensions";
import http from "../../helpers/http";
import helper from "../../helpers/helper";
import config from "../../config/config";
import moment from "moment";
import { useTranslation } from "react-i18next";
function Index() {
  const [loading, setloading] = useState(false);

  const [datas, setDatas] = useState([]);
  const [data, setData] = useState({});
  const [proData, setProData] = useState({});
  const [proEntries, setProEntries] = useState([]);
  const [drawerOpen, setdraweropen] = useState(false);
  const [isProDrawerOpen, setIsProDrawerOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();

  const moduleNamePural = t("contact.moduleNamePural");
  const base_url = config.api_url + "admin/leads/contact"; //without trailing slash
  const image_url = config.api_url + ""; //with trailing slash

  const fetchDatas = async () => {
    setloading(true);
    const data = await http.get(base_url);

    if (data) {
      setDatas(data.data);
    }
    setloading(false);
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  const handleView = async (id) => {
    const { data } = await http.get(`${base_url}/${id}`);

    if (data) {
      setData(data);
      console.log(data);
      setdraweropen(true);
    }
  };


  const handleDrawerClose = () => {
    setData({});
    setdraweropen(false);
  };
  const handleProDrawerClose = () => {
    setProData({});
    setIsProDrawerOpen(false);
  };



  const fields = {
   

    first_name: t("contact.columns.firstName"),
    last_name: t("contact.columns.lastName"),
    phone: t("contact.columns.contactNumber"),
    email: t("contact.columns.emailAddress"),
    special_request: t("contact.columns.specialRequest"),
  
    
  

    createdAt: {
      header: t("contact.columns.createdAt"),
      formatter: (_fieldValue, record) => {
        return  `${helper.ISTDate(record.createdAt)}`;
      },
    },
  };

  const columns = [
    {
      title: t("contact.columns.fullName"),

      key: "name",
      render: (text, record) => {
        return `${record.first_name} ${record.last_name}`;
      },
    },

   
    {
      title: t("contact.columns.contactNumber"),
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: t("contact.columns.emailAddress"),
      dataIndex: "email",
      key: "email",
    },
   

 
    {
      title: t("contact.columns.action"),
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              handleView(record._id);
            }}
          >
            {t("common.viewDetails")}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {/* <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Leads</Breadcrumb.Item>
        <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>
      </Breadcrumb> */}
      <Breadcrumb
        style={{ margin: "16px 0" }}
        items={[
          {
            title: t("common.dashboard"),
          },

          {
            title: moduleNamePural,
          },
        ]}
      />

      <Row>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card title={moduleNamePural} bordered={false}>
              <Table
                rowKey={(record) => record._id}
                columns={columns}
                dataSource={datas}
                loading={loading}
                exportableProps={{
                  showColumnPicker: false,
                  fields,
                  fileName: moduleNamePural,
                  children:t("common.exportToCsv")
                }}
                searchable
              />
            </Card>

            <Drawer
              title={t("common.moreDetails")}
              placement="right"
              onClose={handleDrawerClose}
              open={drawerOpen}
              size="large"
              width={1000}
            >
              {data && (
                <Descriptions
                  bordered
                  column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                  size="small"
                >
             
                  <Descriptions.Item label={t("contact.columns.firstName")}>
                    {data.first_name || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("contact.columns.lastName")}>
                    {data.last_name || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={t("contact.columns.contactNumber")}
                  >
                    {data.phone || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("contact.columns.emailAddress")}>
                    {data.email || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={t("contact.columns.specialRequest")}
                  >
                    {data.special_request || "NA"}
                  </Descriptions.Item>
                

                  <Descriptions.Item label={t("contact.columns.createdAt")}>
                    {`${helper.ISTDate(data.createdAt)}`}
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Drawer>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Index;
