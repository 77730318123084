import React, { useEffect, useState } from "react";
// import tinymce from "tinymce/tinymce";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Form,
  Input,
  notification,
  Upload,
  Button,
  Spin,
  Image,
  Radio,
  DatePicker,
  InputNumber,
} from "antd";

import _ from "lodash";
import http from "../../helpers/http";
import config from "../../config/config";
import { QuestionCircleOutlined } from "@ant-design/icons";

import HtmlEditor from "../../components/HtmlEditor";

import Joi from "joi";
import moment from "moment";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
function Setting() {
  const [editOpen, setEditOpen] = useState(false);
  const [formError, setFormError] = useState({});
  const [data, setData] = useState({});
  const [loading, setloading] = useState(false);
  const [btnloading, setbtnloading] = useState(false);
  const [isHelpShow, setIsHelpShow] = useState(false);
  const [description, setDescription] = useState();
  const [descriptionthree, setdescriptionthree] = useState();
  const [dates, setDates] = useState([]);
  const { t } = useTranslation();

  //createKey and editKey is used to reset modal data on close

  const [editKey, seteditKey] = useState(Math.random() * 10);

  const moduleName = t("settings.moduleName");
  const moduleNamePural = t("settings.moduleNamePural");
  const base_url = config.api_url + "admin/content/setting"; //without trailing slash
  const image_url = config.api_url + "uploads/content/setting/"; //with trailing slash
  const sizeLimit = 10;

  const { TextArea } = Input;

  const schema = Joi.object({
    price:Joi.number()
  }).options({ allowUnknown: true });

  const validate = async (value) => {
    const { error } = schema.validate(value, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    // if (value.stockGraph) {
    //   if (value.stockGraph.file.size > sizeLimit * 1024 * 1024) {
    //     errors["stockGraph"] = `File needs to be under ${sizeLimit}MB`;
    //   }
    //   let allowedExt = ["image/jpeg", "image/png"];
    //   if (!allowedExt.includes(value.stockGraph.file.type)) {
    //     errors["stockGraph"] = "File does not have a valid file extension.";
    //   }
    // }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    value.block_dates = dates.filter((n) => n);
    console.log({ value });
    let errors = await validate(value);

    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setbtnloading(true);
    let formData = new FormData();
    for (const [key, data] of Object.entries(_.omit(value, []))) {
      formData.append(key, data || "");
    }

    // if (value.stockGraph) {
    //   formData.append("stockGraph", value.stockGraph.file);
    // }

    const { data } = await http.put(base_url, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
        message: t("settings.notifications.updated", {
          moduleName: moduleName,
        }),
      });
      setbtnloading(false);
      form.resetFields();
      fetchData();
    }
  };

  const fetchData = async (id) => {
    setloading(true);
    const { data } = await http.get(base_url);

    if (data) {
      setData(data);
    }
    setloading(false);
  };

  const handleEditClose = () => {
    seteditKey((state) => {
      return state + Math.random() * 2;
    });
    setData({});
    setEditOpen(false);
  };

  const [form] = Form.useForm();
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // form.setFieldsValue(data);
    form.setFieldsValue(_.omit(data, ["block_dates"]));

    setDates(
      data?.block_dates?.map((date) => dayjs(date).format("YYYY-MM-DD"))
    );
  }, [data]);

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>{t("common.dashboard")}</Breadcrumb.Item>
        <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card
              title={moduleNamePural}
              bordered={true}
              // extra={
              //   <QuestionCircleOutlined onClick={() => setIsHelpShow(true)} />
              // }
              // style={{ boxShadow: "10px 0px 15px rgba(0,0,0,0.1)" }}
            >
              <Row style={{ marginBottom: "20px" }}></Row>

              <Form
                form={form}
                onFinish={handleSubmit}
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                layout="vertical"
              >
                <Row gutter={16}>
                  <Col xs={24} md={24}>
                    <Form.Item
                      label={`${t("settings.fields.blockedDates")}:`}
                      name="block_dates"
                      {...(formError.block_dates && {
                        help: formError.block_dates,
                        validateStatus: "error",
                      })}
                    >
                      <DatePicker
                        multiple
                        onChange={(date, datestring) => {
                          console.log({ date, datestring });
                          setDates(datestring);
                        }}
                        maxTagCount="responsive"
                        defaultValue={data?.block_dates?.map((date) =>
                          dayjs(date)
                        )}
                        // size="small"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4}>
                    <Form.Item
                      label={`${t("settings.fields.price")}:`}
                      name="price"
                      {...(formError.price && {
                        help: formError.price,
                        validateStatus: "error",
                      })}
                    >
                      {/* <InputNumber
                        // defaultValue={1000}
                        formatter={(value) =>
                          `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                        onChange={(value)=>{
                          console.log({value})
                        }}
                      /> */}

                      <Input addonBefore="€" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Form.Item
                    // wrapperCol={{ offset: 8, span: 16 }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={btnloading}
                      >
                        {t("common.update")}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Setting;
