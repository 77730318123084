import { Menu, Popover, Select } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GlobalOutlined } from "@ant-design/icons";
import IndiaFlag from "../icons/IndiaFlag"
import UsaFlag from "../icons/UsaFlag"
import FranceFlag from "../icons/FranceFlag"


// const languages = [
//   {
//     value: "en",
//     label: "English",
//   },
//   {
//     value: "fr",
//     label: "French",
//   },
// ];

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  const changeLanguage = ({ key }) => {
    // console.log(key)
    i18n.changeLanguage(key);
  };

  const languages = [
    {
      icon:<UsaFlag   />,
      key: "en",
      label: "English",
    },
    {
      icon:<FranceFlag/>,
      key: "fr",
      label: "French",
    },
  ];

  const content = (
    <Menu
      theme="light"
      mode="inline"
      items={languages}
      defaultSelectedKeys={[i18n.resolvedLanguage]}
      onSelect={changeLanguage}
    ></Menu>
  );

  return (
    <>
      {/* <Select
        className="language_selector"
        style={{
          width: 120,
        }}
        onChange={changeLanguage}
        options={languages}
      /> */}
      <Popover content={content}>
        <GlobalOutlined />
      </Popover>
    </>
  );
};

export default LanguageSelector;
